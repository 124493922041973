define("discourse/plugins/chat/discourse/components/chat/modal/thread-settings", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _object, _service, _dButton, _dModal, _ajaxError, _i18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _ChatModalThreadSettings;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatModalThreadSettings = _exports.default = (_class = (_ChatModalThreadSettings = class ChatModalThreadSettings extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "editedTitle", _descriptor2, this);
      _initializerDefineProperty(this, "saving", _descriptor3, this);
    }
    get buttonDisabled() {
      return this.saving;
    }
    get thread() {
      return this.args.model;
    }
    saveThread() {
      this.saving = true;
      this.chatApi.editThread(this.thread.channel.id, this.thread.id, {
        title: this.editedTitle
      }).then(() => {
        this.thread.title = this.editedTitle;
        this.args.closeModal();
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.saving = false;
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal
        @closeModal={{@closeModal}}
        class="chat-modal-thread-settings"
        @inline={{@inline}}
        @title={{i18n "chat.thread.settings"}}
      >
        <:body>
          <label for="thread-title" class="thread-title-label">
            {{i18n "chat.thread.title"}}
          </label>
          <Input
            name="thread-title"
            class="chat-modal-thread-settings__title-input"
            @type="text"
            @value={{this.editedTitle}}
          />
        </:body>
        <:footer>
          <DButton
            @disabled={{this.buttonDisabled}}
            @action={{this.saveThread}}
            @label="save"
            class="btn-primary"
          />
        </:footer>
      </DModal>
    
  */
  {
    "id": "1/aZ9I0E",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"chat-modal-thread-settings\"]],[[\"@closeModal\",\"@inline\",\"@title\"],[[30,1],[30,2],[28,[32,1],[\"chat.thread.settings\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,\"label\"],[14,\"for\",\"thread-title\"],[14,0,\"thread-title-label\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"chat.thread.title\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[32,2],[[24,3,\"thread-title\"],[24,0,\"chat-modal-thread-settings__title-input\"]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"editedTitle\"]]]],null],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],[[24,0,\"btn-primary\"]],[[\"@disabled\",\"@action\",\"@label\"],[[30,0,[\"buttonDisabled\"]],[30,0,[\"saveThread\"]],\"save\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@inline\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/thread-settings.js",
    "scope": () => [_dModal.default, _i18n.default, _component2.Input, _dButton.default],
    "isStrictMode": true
  }), _ChatModalThreadSettings), _ChatModalThreadSettings), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "editedTitle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.thread.title || "";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "saveThread", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveThread"), _class.prototype)), _class);
});